import _ from "lodash";
import moment from "moment";

// The key used to store the locale in the cookie.
// https://nextjs.org/docs/pages/building-your-application/routing/internationalization#leveraging-the-next_locale-cookie
const NEXT_LOCALE_COOKIE_KEY = "NEXT_LOCALE";

export const CROWDLOAN_STATE = {
  PRELAUNCH: "PRELAUNCH",
  LAUNCH: "LAUNCH",
  ENDED: "ENDED",
};

export const CROWDLOAN_TYPE = {
  KSM: "KSM",
  DOT: "DOT",
};

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 0) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

/**
 * Formatting number string with thousand separator.
 * @param  {number} num e.g. 1000000.65
 * @return {string}   "1,000,000.65"
 */
export function formatNumberStrThousands(numStr) {
  if (_.isEmpty(numStr)) {
    return numStr;
  }

  const parts = numStr.split(".");

  const decimalStr = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const period = _.isUndefined(parts[1]) ? "" : ".";
  const floatStr = _.isUndefined(parts[1]) ? "" : parts[1];

  return `${decimalStr}${period}${floatStr}`;
}

/**
 * Formatting number with thousand separator.
 * @param  {number} num e.g. 1000000.65
 * @return {string}   "1,000,000.65"
 */
export function formatNumberThousands(num) {
  if (_.isUndefined(num)) {
    return num;
  }

  const numStr = num.toString();
  return formatNumberStrThousands(numStr);
}

export const scrollToAnchor = (anchorName) => {
  const anchorElement = document.getElementById(anchorName);
  if (anchorElement) {
    anchorElement.scrollIntoView({ block: "start", behavior: "smooth" });
  }
};

/**
 * Limit decimal places
 * @param {*} number
 * @returns
 */
export const numberToFixed = (number, precision = 4) => Number(number.toFixed(precision)) / 1;

export const getUrlSearchParams = (urlObject) => Object.fromEntries(urlObject.searchParams);
export const getUrlSearchParam = (urlObject, key) => getUrlSearchParams(urlObject)[key];

export const getPageUrl = () => {
  const url = new URL(window?.location?.href);
  return url.origin + url.pathname;
};

export const calculateCrowdloanState = (crowdloanBeginTime, crowdloanEndTime) => {
  if (moment().isBefore(moment(crowdloanBeginTime))) {
    return CROWDLOAN_STATE.PRELAUNCH;
  }
  if (moment().isBefore(moment(crowdloanEndTime))) {
    return CROWDLOAN_STATE.LAUNCH;
  }
  return CROWDLOAN_STATE.ENDED;
};

/**
 * Handles key press events for a custom interactive element, mainly to comply with
 * eslint rule, jsx-a11y/click-events-have-key-events
 * Specifically, it checks for 'Enter' or 'Space' keys and, if pressed,
 * prevents the default behavior and calls the specified function.
 *
 * @param {Event} e - The event object generated by the key press.
 * @param {Function} callFunc - The function to be called when 'Enter' or 'Space' is pressed.
 * @param {Array} params - An array of parameters to be passed to the callFunc.
 */
export const onKeyPressed = async (e, callFunc, params) => {
  // Check if the key is 'Enter' or 'Space'
  if (e.key === "Enter" || e.key === " ") {
    e.preventDefault(); // Prevent any default behavior for these keys
    callFunc([...params]); // Call your onClick handler
  }
};

export const numberFloor = (num, precision) => {
  const numStr = num.toString();
  const dotIndex = numStr.indexOf(".");
  const flooredNumberStr = dotIndex !== -1 ? numStr.substring(0, precision + dotIndex + 1) : numStr;
  return Number(flooredNumberStr) / 1;
};

export const shortenAddress = (address, option = { omitStr: "...", preLength: 4, subLength: 4 }) => {
  const { omitStr, preLength, subLength } = option;
  if (_.isEmpty(address) || address.length < preLength + subLength) {
    return address;
  }
  return `${address.substring(0, preLength)}${omitStr}${address.substring(address.length - subLength, address.length)}`;
};

export const shortenAmount = (amount, precision) =>
  amount < 1000 ? numberToFixed(amount, precision) : `${formatNumberStrThousands(numberFloor(amount / 1000, 1))} K`;

export const makeTimeoutPromise = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

/**
 * Save the language to the cookie.
 * Set the locale cookie.
 * This cookie can be set using a language switcher
 * and then when a user comes back to the site it will leverage the locale specified in the cookie
 * when redirecting from / to the correct locale location.
 * With a path parameter, you can tell the browser what path the cookie belongs to.
 * Set the cookie path to the root of the website.
 * @param {*} language
 */
export const saveLanguageToCookie = (language) => (document.cookie = `${NEXT_LOCALE_COOKIE_KEY}=${language}; path=/`);
