"use client";

import _ from "lodash";
import type { NavbarProps } from "@nextui-org/react";
import { header, Item } from "./data";
import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "next-i18next";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Link,
  Button,
  Divider,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import Image from "next/image";

import { cn } from "../cn";
import config from "../../config";
import Body from "../typography/body";
const { company, appsURL } = config;

/**
 * Returns true if the item link is the same as the current pathname
 * If the item has a group, it will check if any of the group’s sub-item link is the same as the current pathname
 * @param pathname
 * @param item
 * @returns
 */
const isPathActive = (pathname: string, item: Item) => {
  if (item.link) {
    if (item.link === pathname) {
      return true;
    }
  } else {
    return _.some(item.groups, (group) => {
      return _.some(group.items, (groupItem) => {
        if (groupItem.link === pathname) {
          return true;
        }
      });
    });
  }

  return false;
};

const renderSimple = (t: any, item: Item, pathname: string) => {
  const { text, link } = item;

  return (
    <NavbarItem key={text}>
      <Button
        className={cn(
          "lg:px-2 xl:px-4",
          { "text-default-500": !isPathActive(pathname, item) },
          { "text-foreground": isPathActive(pathname, item) },
        )}
        variant={isPathActive(pathname, item) ? "flat" : "light"}
        as={Link}
        href={item.link}
      >
        <Body as="span" variant="medium">
          {t(text)}
        </Body>
      </Button>
    </NavbarItem>
  );
};

const renderDropdown = (t: any, item: Item, pathname: string) => {
  const { text, groups: groups = [] } = item;

  return (
    <NavbarItem key={text}>
      <Popover
        classNames={{
          content: "p-2",
        }}
        placement="bottom"
        showArrow
        offset={10}
      >
        <PopoverTrigger>
          <Button
            className={cn(
              "lg:px-2 xl:px-4",
              { "text-default-500": !isPathActive(pathname, item) },
              { "text-foreground": isPathActive(pathname, item) },
            )}
            variant={isPathActive(pathname, item) ? "flat" : "light"}
            endContent={<Icon icon="lucide:chevron-down" />}
          >
            <Body as="span" variant="medium">
              {t(text)}
            </Body>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto">
          {(titleProps) => (
            <div className="flex flex-col">
              {_.map(groups, (group, index) => (
                <div key={group?.label?.text}>
                  {/* Potentially add group title and icons; if the popover contains multiple groups */}
                  {_.map(group.items, (groupItem) => (
                    <div key={groupItem?.text}>
                      <Button
                        key={groupItem?.text}
                        className={cn(
                          "w-full",
                          { "text-default-500": !isPathActive(pathname, groupItem) },
                          { "text-foreground": isPathActive(pathname, groupItem) },
                        )}
                        variant="light"
                        as={Link}
                        href={groupItem.link}
                      >
                        <Body as="span" variant="medium">
                          {t(groupItem.text)}
                        </Body>
                      </Button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </PopoverContent>
      </Popover>
    </NavbarItem>
  );
};

const expandNavItems = (menuItems: any, level: number) => {
  const items = [];
  _.each(menuItems, (item) => {
    items.push({ ...item, level });
    if (item.groups) {
      _.each(item.groups, ({ items: groupItems }) =>
        _.each(expandNavItems(groupItems, level + 1), (innerItem) => items.push(innerItem)),
      );
    }
  });
  return items;
};

const generateNavItemElements = (t: any, menuItems: any, pathname: string) => {
  const items = expandNavItems(menuItems, 0);
  return _.map(items, (item, index) => {
    const { text, link, level } = item;
    const itemTextClassname = `mb-1 md:mb-2 w-full ${level === 0 ? "" : "pl-5"}`;
    return (
      <NavbarMenuItem key={`${text}-${index}`} isActive={isPathActive(pathname, item)}>
        <Link
          className={cn(
            itemTextClassname,
            { "text-default-500": !isPathActive(pathname, item) },
            { "text-foreground": isPathActive(pathname, item) },
          )}
          href={link}
          size="md"
        >
          <Body as="span" variant="medium">
            {t(text)}
          </Body>
        </Link>
      </NavbarMenuItem>
    );
  });
};

export default function Component(props: NavbarProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const router = useRouter();

  const { pathname } = router;

  const { t } = useTranslation("header");

  return (
    <Navbar
      {...props}
      classNames={{
        base: cn(
          "border-default-100",
          {
            "bg-default-200/50 dark:bg-default-100/50": isMenuOpen,
          },
          "bg-transparent",
        ),
        wrapper: "w-full sm:py-4 xl:py-10 flex justify-center lg:gap-2 xl:gap-x-8 2xl:gap-x-12 lg:px-2 xl:px-6",
        item: "hidden lg:flex",
        content: "p-1 lg:gap-x-1 xl:gap-x-4",
      }}
      height="80px"
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      {/* Left Content */}
      <NavbarBrand>
        <Link href="/">
          <Image
            src="/image/logo-dark-horizontal.png"
            width={220}
            height={32}
            alt={`${company} logo`}
            className="min-w-[180px]"
          />
        </Link>
      </NavbarBrand>

      {/* Center Content */}
      <div className="grow flex justify-center">
        <NavbarContent justify="center" className="bg-zinc-400/15 rounded-xl w-fit">
          {_.map(header, (menuItem) =>
            _.isEmpty(menuItem.groups) ? renderSimple(t, menuItem, pathname) : renderDropdown(t, menuItem, pathname),
          )}
        </NavbarContent>
      </div>

      {/* Right Content */}
      <NavbarContent className="hidden lg:flex" justify="end">
        <NavbarItem className="flex lg:gap-x-2 xl:gap-x-4">
          <Button color="primary" variant="shadow" className="lg:px-4 xl:px-6 py-4" as={Link} href="/contact-us">
            <Body as="span" variant="large-stronger">
              {t("contact-us")}
            </Body>
          </Button>

          <Button
            color="primary"
            variant="bordered"
            className="lg:px-4 xl:px-6 py-4"
            as={Link}
            href={appsURL}
            isExternal
          >
            <Body as="span" variant="large-stronger">
              {t("try-testnet")}
            </Body>
          </Button>
        </NavbarItem>
      </NavbarContent>

      <NavbarMenuToggle className="text-default-500 lg:hidden" />

      <NavbarMenu
        className="top-[calc(var(--navbar-height)_-_1px)] max-h-fit bg-default-200/50 pb-6 pt-6 md:pl-12 shadow-medium backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50"
        motionProps={{
          initial: { opacity: 0, y: -20 },
          animate: { opacity: 1, y: 0 },
          exit: { opacity: 0, y: -20 },
          transition: {
            ease: "easeInOut",
            duration: 0.2,
          },
        }}
      >
        {generateNavItemElements(t, header, pathname)}
        <Divider className="opacity-50" />
        <NavbarMenuItem key={`cta-buttons`}>
          <div className="flex gap-4 pt-2 md:pt-4">
            <Button color="primary" variant="bordered" className="px-6 py-4" as={Link} href={appsURL} isExternal>
              <Body as="span" variant="large-stronger">
                {t("try-testnet")}
              </Body>
            </Button>
            <Button color="primary" variant="shadow" className="px-6 py-4" as={Link} href="/contact-us">
              <Body as="span" variant="large-stronger">
                {t("contact-us")}
              </Body>
            </Button>
          </div>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
