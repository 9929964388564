"use client";

import _ from "lodash";
import React from "react";
import { Button, Link, Spacer, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Icon } from "@iconify/react";
import Image from "next/image";

import { saveLanguageToCookie } from "@/utils/helperFn";

import config from "../../config";
import { footer } from "./data";

const { company, socialMedia, languages } = config;

/**
 * Returns true if the URL is external
 */
function isExternalUrl(url) {
  // Check if the URL starts with http:// or https://
  return /^https?:\/\//.test(url);
}

export default function Footer() {
  const router = useRouter();
  const { t, i18n } = useTranslation("footer");

  const onLanguageSelected = (key: string) => {
    // Redirect to the page in the specified language by changing the locale in the TransitionOptions parameter.
    // https://github.com/i18next/next-i18next/blob/d4ee4ff8661a8bb6ce89fc36edeaa0de04a39c39/examples/simple/pages/index.tsx#L24
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: key });
    saveLanguageToCookie(key);
  };

  return (
    <footer className="flex w-full flex-col">
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-24">
        <div className="flex items-center justify-center gap-2">
          <Image src="/image/logo-dark-horizontal.png" width={220} height={32} alt={`${company} logo`} />
        </div>
        <Spacer y={4} />
        <div className="flex flex-wrap justify-center gap-x-4 gap-y-1">
          {_.map(footer, (item) => (
            <Link
              key={item.name}
              className="text-default-500"
              href={item.href}
              size="sm"
              {...(isExternalUrl(item.href) && {
                isExternal: true,
                showAnchorIcon: true,
              })}
            >
              {t(item.name)}
            </Link>
          ))}
        </div>
        <Spacer y={6} />
        <div className="flex justify-center gap-x-4">
          {_.map(socialMedia, ({ name, link, icon }) => (
            <Link key={name} isExternal className="text-default-400" href={link}>
              <span className="sr-only">{name}</span>
              <Icon className="w-5" icon={icon} />
            </Link>
          ))}

          <Dropdown>
            <DropdownTrigger>
              <Button
                variant="flat"
                radius="full"
                startContent={<Icon icon="lucide:globe" />}
                endContent={<Icon icon="lucide:chevron-down" />}
              >
                {_.find(languages, { key: i18n.language })?.name}
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="languages" onAction={onLanguageSelected}>
              {_.map(languages, ({ key, name }) => (
                <DropdownItem key={key}>{name}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>

        <Spacer y={4} />
        <p className="mt-1 text-center text-small text-default-400">
          &copy; {new Date().getFullYear()} {company}. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
