"use client";

import React, { useState } from "react";
import { Button, Link } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useTranslation, Trans } from "next-i18next";
import { cn } from "@/components/cn";

const WHY_BLOG = "/blog/ava-protocol-launches-an-event-driven-eigenlayer-avs-on-ethereum";
const OPERATOR_TUTORIAL = "/docs/ethereum/run-operator/setup-node";

function Banner() {
  // State to manage if the banner is shown or not
  const [showBanner, setShowBanner] = useState(true);
  const { t } = useTranslation("header");

  // Function to handle the click event on the close button
  const handleCloseClick = () => {
    setShowBanner(false); // This will set showBanner to false, hiding the banner
  };

  // Conditional rendering based on showBanner state
  if (!showBanner) {
    return null; // If showBanner is false, don't render anything
  }

  return (
    <div className={cn("flex w-full items-center gap-x-3 gap-y-3 border-b-1 border-divider bg-gradient-to-r from-default-100 via-danger-100 to-secondary-100 px-6 py-2 sm:px-3.5 sm:before:flex-1",
      "flex-wrap md:flex-nowrap"
    )}>
      <p className="text-small text-foreground w-full md:w-auto">
        <Trans
          t={t}
          i18nKey="cta-banner"
          components={[
            <Link key="cta-banner-link-1" href={WHY_BLOG} color="foreground" className="underline underline-offset-4">
              why this is a game-changer
            </Link>,
            <Link
              key="cta-banner-link-2"
              href={OPERATOR_TUTORIAL}
              color="foreground"
              className="underline underline-offset-4"
            >
              how to run
            </Link>,
          ]}
        ></Trans>
      </p>
      <Button
        as={Link}
        className="group relative h-9 overflow-hidden bg-transparent text-small font-normal"
        color="default"
        endContent={
          <Icon
            className="flex-none outline-none transition-transform group-data-[hover=true]:translate-x-0.5 [&>path]:stroke-[2]"
            icon="solar:arrow-right-linear"
            width={16}
          />
        }
        href={OPERATOR_TUTORIAL}
        isExternal={false}
        style={{
          border: "solid 2px transparent",
          backgroundImage: `linear-gradient(hsl(var(--nextui-danger-50)), hsl(var(--nextui-danger-50))), linear-gradient(to right, #F871A0, #9353D3)`,
          backgroundOrigin: "border-box",
          backgroundClip: "padding-box, border-box",
        }}
        variant="bordered"
      >
        {t("cta-banner-btn")}
      </Button>
      <div className="flex flex-1 justify-end">
        <Button
          isIconOnly
          aria-label="Close Banner"
          className="-m-1"
          size="sm"
          variant="light"
          onClick={handleCloseClick}
        >
          <Icon aria-hidden="true" className="text-default-500" icon="lucide:x" width={20} />
        </Button>
      </div>
    </div>
  );
}

export default Banner;
