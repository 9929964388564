import React from "react";
import _ from "lodash";
import { cn } from "@/components/cn";

interface BodyProps {
  as?: "h2" | "h3" | "h4" | "p" | "span" | "div";
  children: React.ReactNode;
  variant?:
    | "large"
    | "large-strong"
    | "large-stronger"
    | "medium"
    | "small"
    | "small-strong"
    | "small-stronger"
    | "extra-small"
    | "extra-small-stronger";
  className?: string;
}

const defaultStyles = {
  fontSize: "text-sm",
  fontWeight: "font-normal",
  lineHeight: "leading-normal",
};

function getStyles(variant: BodyProps["variant"]) {
  let styles = _.cloneDeep(defaultStyles);
  switch (variant) {
    case "large":
      styles.fontSize = "text-base";
      break;
    case "large-strong":
      styles.fontSize = "text-base";
      styles.fontWeight = "font-medium";
      break;
    case "large-stronger":
      styles.fontSize = "text-base";
      styles.fontWeight = "font-bold";
      break;
    case "medium":
      break;
    case "small":
      styles.fontSize = "text-xs";
      break;
    case "small-strong":
      styles.fontSize = "text-xs";
      styles.fontWeight = "font-bold";
      break;
    case "small-stronger":
      styles.fontSize = "text-xs";
      styles.fontWeight = "font-black";
      break;
    case "extra-small":
      styles.fontSize = "text-[10px]";
      break;
    case "extra-small-stronger":
      styles.fontSize = "text-[10px]";
      styles.fontWeight = "font-medium";
      break;
    default:
      break;
  }

  return _.values(styles);
}

const Body: React.FC<BodyProps> = ({ as: Tag = "p", children, variant = "medium", className = "" }) => {
  return (
    <Tag className={cn(getStyles(variant), className)} suppressHydrationWarning>
      {children}
    </Tag>
  );
};

export default Body;
