import config from "../../config";

const { mediaKit, career } = config;

type Item = {
  text: string;
  description?: string;
  link?: string;
  groups?: ItemGroup[];
};

type Label = {
  icon: string;
  text: string;
};

type ItemGroup = {
  label: Label;
  items: Item[];
};

const header: Item[] = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "solutions",
    groups: [
      {
        label: {
          icon: "lucide:layers",
          text: "features",
        },
        items: [
          {
            text: "ethereum-eigenlayer-avs",
            link: "/solutions/ethereum",
          },
          {
            text: "soneium",
            link: "/solutions/soneium",
          },
          {
            text: "polkadot",
            // link: "/polkadot",
          },
        ],
      },
    ],
  },
  {
    text: "use-cases",
    groups: [
      {
        label: {
          icon: "lucide:layers",
          text: "use-cases",
        },
        items: [
          {
            text: "for-developers",
            link: "/usecases/developer",
          },
          {
            text: "for-defi",
            link: "/usecases/defi",
          },
          {
            text: "for-nft",
            link: "/usecases/nft",
          },
          {
            text: "for-rwa",
            link: "/usecases/rwa",
          },
          {
            text: "for-gamefi",
            link: "/usecases/gamefi",
          },
        ],
      },
    ],
  },

  // {
  //   text: "network",
  //   groups: [
  //     {
  //       label: {
  //         icon: "lucide:layers",
  //         text: "ethereum",
  //       },
  //       items: [
  //         {
  //           text: "become-an-operator",
  //           description: "Help run the AVS network",
  //           link: "/avs-operator",
  //         },
  //         {
  //           text: "reward-explorer",
  //           description: "Build crypto apps that scale",
  //           link: "/eth-reward",
  //         },
  //         {
  //           text: "avs-status",
  //           description: "View network performance and status",
  //           link: "/avs-status",
  //         },
  //       ],
  //     },
  //     {
  //       label: {
  //         icon: "lucide:layers",
  //         text: "polkadot",
  //       },
  //       items: [
  //         {
  //           text: "become-a-validator",
  //           description: "Run the network by validating transactions",
  //           link: "/collator",
  //         },
  //         {
  //           text: "staking",
  //           description: "Earn rewards by staking your tokens",
  //           link: "/staking",
  //         },
  //         {
  //           text: "network-status",
  //           description: "View the current status of the network",
  //           link: "/network-status",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    text: "community",
    groups: [
      {
        label: {
          icon: "lucide:layers",
          text: "get-involved",
        },
        items: [
          {
            text: "community-hub",
            link: "/community",
          },
          {
            text: "news",
            link: "/news",
          },
          {
            text: "blog",
            link: "/blog",
          },
          // {
          //   text: "events",
          //   link: "/events",
          // },
          // {
          //   text: "ambassador-program",
          //   link: "/ambassadors",
          // },
        ],
      },
    ],
  },
  {
    text: "developers",
    groups: [
      {
        label: {
          icon: "lucide:layers",
          text: "start-building",
        },
        items: [
          {
            text: "dev-labs",
            link: "/learn",
          },
          {
            text: "dev-docs",
            link: "/docs",
          },
          // {
          //   text: "open-grant",
          //   description: "Apply for a grant to build",
          //   link: "/grants",
          // },
        ],
      },
    ],
  },
];

const footer = [
  {
    name: "home",
    href: "/#",
  },
  {
    name: "team",
    href: "/team",
  },
  {
    name: "blog",
    href: "/blog",
  },
  {
    name: "docs",
    href: "/docs",
  },
  {
    name: "jobs",
    href: career,
  },
  {
    name: "media-kit",
    href: mediaKit,
    isExtrenal: true,
  },
];

export { header, footer };

export type { Item, Label };
